import { graphql } from 'gatsby';

// Redirect page:
const IndexPage = ({ data }) => {
  if (typeof window !== 'undefined') {
    if (window.location.pathname === '/' || window.location.pathname === '') {
      window.location.assign('/givingtuesday2024');
    } else {
      window.location.assign('/home');
    }
  }
  return null;
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            contentType
            date(formatString: "MMMM DD, YYYY")
            path
            hidden
          }
        }
      }
    }
  }
`;
